<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-sider
      style="background: #fff; max-width: 185px; min-width: 185px; width: 185px"
      v-model="collapsed"
      :trigger="null"
      collapsible
    >
      <Sidebar :collapsed="collapsed" />
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; padding: 0; height: 100px">
        <Topbar :collapsed="collapsed" @changeCollapsed="changeCollapsed" />
        <AliveTab />
      </a-layout-header>
      <a-layout-content style="margin-top: 4px">
        <guide-bar v-if="ready && guideBarShow" @cancel="cancelGuide"></guide-bar>
        <keep-alive :exclude="excludename">
          <!-- 主应用路由渲染出口 -->
          <router-view v-if="isRouterAlive"></router-view>
        </keep-alive>
        <!-- 微前端子应用渲染出口 -->
        <div class="loading-content" v-if="dashboard_loading">
          <a-progress style="width: 60%; margin: 100px auto" :percent="defaultPercent"></a-progress>
          <!-- <span v-html="loadingTips"></span> -->
        </div>
        <div id="subapp-viewport"></div>
        <div class="csdn-side-toolbar" style="bottom: 64px !important" v-if="showIcon">
          <a-icon type="close-circle" class="close-icon" @click="showIcon = false" />
          <a class="option-box" data-type="cs" @click="toWorkOrder">
            <img src="https://g.csdnimg.cn/side-toolbar/3.4/images/kefu.png" alt="" srcset="" />
            <span class="show-txt">{{ $t('客服') }}</span>
          </a>
        </div>
      </a-layout-content>
    </a-layout>
    <WorkOrderSidebar ref="workOerderRef" />
  </a-layout>
</template>
<script>
// import api from '@service/api'
// import http from '@service/axios'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { getCookie, deleteCookie } from '@common/cookie'
import { Layout } from 'ant-design-vue'
import AliveTab from '@component/topBar/aliveTab.vue'
import Sidebar from '@component/sideBar/sidebar.vue'
import Topbar from '@component/topBar/topbar.vue'
import WorkOrderSidebar from '@component/workOrderSidebar.vue'
// import { priv } from '@/common/privilege.js'

const excludename = 'addPurchase,advancedShippingNoteDetail,orderSplit'

export default {
  Name: 'Home',
  components: {
    Sidebar,
    Topbar,
    AliveTab,
    'a-layout': Layout,
    'a-layout-sider': Layout.Sider,
    'a-layout-header': Layout.Header,
    'a-layout-content': Layout.Content,
    WorkOrderSidebar,
  },
  computed: {
    ...mapState(['exclude', 'userSession', 'dashboard_loading']),
    ...mapGetters(['loadingTips']),
    routeName() {
      return this.$route.name
    },
    currentModule() {
      return this.global.currentModule ? this.global.currentModule.view : this.global.currentModule
    },
  },
  data() {
    return {
      excludename,
      collapsed: false,
      isRouterAlive: true,
      ready: false,
      guideBarShow: false,
      currentSession: null,
      defaultPercent: 0,
      timer: null,
      showIcon: true,
    }
  },
  mounted() {
    let userSession = this.$store.state.userSession
    if (!getCookie('d_session_id') || !userSession?.merchantCode) {
      //假如没有session的话
      this.$message.destroy()
      this.$message.warning(this.$t('登录超时'))
      localStorage.clear()
      setTimeout(() => {
        deleteCookie(() => {
          window.location.href = '/login'
        })
      }, 500)
      return
    }
  },
  methods: {
    ...mapMutations(['setState']),
    changeCollapsed() {
      this.collapsed = !this.collapsed
    },
    reload(newVal) {
      this.isRouterAlive = false
      this.excludename = newVal
      this.$nextTick(() => {
        this.excludename = excludename
        this.setState({
          exclude: null,
        })
        this.isRouterAlive = true
      })
    },
    topBarReady() {
      this.ready = true
    },

    topBarUnready() {
      this.ready = false
    },
    showGuideBar(data) {
      this.guideBarShow = data
    },
    cancelGuide() {
      this.guideBarShow = false
    },
    toWorkOrder() {
      this.$refs.workOerderRef.open()
    },
  },
  watch: {
    exclude(newVal) {
      // console.log('newVal', newVal)
      if (newVal) {
        this.reload(newVal)
      }
    },
    routeName(val) {
      if (this.global.currentModule && val == this.global.currentModule.view) {
        this.guideBarShow = true
      } else {
        this.guideBarShow = false
      }
    },
    dashboard_loading: {
      handler(val) {
        if (val) {
          this.timer = setInterval(() => {
            if (this.defaultPercent == 90) return
            this.defaultPercent += 10
          }, 500)
        } else {
          clearInterval(this.timer)
          this.timer = null
          this.defaultPercent = 0
        }
      },
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
#components-layout-demo-custom-trigger {
  height: 100%;
}

.ant-layout-sider-collapsed {
  flex: 0 0 80px;
  max-width: 80px !important;
  min-width: 80px !important;
  width: 80px !important;
}

.ant-layout-content {
  overflow-y: auto;
  scroll-behavior: smooth;
}

.spin-main {
  height: 300px;
}

.loading-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.csdn-side-toolbar {
  position: fixed;
  right: 24px;
  bottom: 120px;
  width: 44px;
  z-index: 998 !important;
  .close-icon {
    position: absolute;
    right: 0;
    top: 10px;
    z-index: 999;
    cursor: pointer;
    display: none;
  }
  a.option-box {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background: #000;
    -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
    background-color: $white;
    text-align: center;
    height: 44px;
    cursor: pointer;
    margin-top: 8px;
    &:hover .show-txt {
      display: block;
      opacity: 1;
    }
    &:hover .img {
      opacity: 0;
    }
    img {
      display: block;
      width: 24px;
    }
    .show-txt {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-size: 12px;
      line-height: 44px;
      color: #999aaa;
      background-color: $white;
      text-align: center;
      opacity: 1;
      -webkit-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &:hover {
    .close-icon {
      display: block;
    }
  }
}
::v-deep .ant-layout-sider-children .sideMenu {
  max-height: calc(100% - 69px) !important;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track-piece {
    background: white;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c1c2;
  }
}
</style>
