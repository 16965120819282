export default (params) => {
  let userSession = params.session
  let module = params.module
  let content = params.content
  let partyName = userSession?.currentParty?.partyName || userSession?.currentParty?.departmentName
  var array = []
  partyName && array.push(partyName)
  let moduleName = module ? module.name : null
  moduleName && array.push(moduleName)
  content && array.push(content)
  let title = ''
  for (let i = array.length - 1; i >= 0; i--) {
    title += array[i]
    if (i != 0) {
      title += ' | '
    }
  }
  document.title = title
}
