<template>
  <a-dropdown placement="bottomRight">
    <div class="menu-icon">
      <i class="icon iconfont icon-global"></i>
    </div>
    <a-menu slot="overlay" @click="changeLangHandle">
      <template v-for="item in lang_list">
        <a-menu-item :key="item.value">
          <span rel="noopener noreferrer" :class="{ active: item.value === lang }">
            {{ item.name }}
          </span>
        </a-menu-item>
      </template>
    </a-menu>
  </a-dropdown>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import actions from '@/qiankun/actions'
export default {
  data() {
    return {
      lang_list: [
        {
          value: 'zh',
          name: '中文',
        },
        {
          value: 'en',
          name: 'English',
        },
        {
          value: 'th',
          name: 'ภาษาไทย',
        },
      ],
    }
  },
  computed: {
    ...mapState(['lang']),
  },
  methods: {
    ...mapMutations(['changeLang']),
    changeLangHandle(item) {
      const key = item.key
      this.changeLang(key)
      actions.setGlobalState({
        lang: key,
      })
      this.$nextTick(() => {
        history.go(0)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.active {
  color: $primary-color;
}
</style>
