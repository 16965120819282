<template>
  <div style="display: flex">
    <a-dropdown placement="bottomRight">
      <div class="menu-icon">
        <span class="user_name">
          {{ currentShop?.name || currentShop?.shopName }}
        </span>
      </div>
      <a-menu slot="overlay" @click="dropClick" mode="vertical">
        <a-menu-item>
          <div class="box">
            <div v-for="item in shopList" :key="item.partyId">
              <a-row class="boxTitle">
                <a-col :span="24">{{ item.partyName }}</a-col>
              </a-row>
              <a-row>
                <a-col :span="12" v-for="(otem, index) in item.children" :key="index">
                  <div
                    class="boxText"
                    :class="{ active: otem.uniqueCode === currentShop.uniqueCode }"
                    @click="dropClick(otem)"
                  >
                    {{ otem.name }}
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <a-dropdown>
      <div class="menu-icon">
        <a-avatar icon="user" :size="23" />
        <span class="user_name" style="font-size: 14px">{{ userName }}</span>
      </div>
      <a-menu slot="overlay" @click="dropClick" mode="vertical">
        <a-menu-item key="change">
          <span rel="noopener noreferrer">
            <i class="fa fa-lock mr10"></i>
            {{ $t(`修改密码`) }}
          </span>
        </a-menu-item>
        <a-menu-item key="logout">
          <span rel="noopener noreferrer">
            <i class="fa fa-sign-out mr10"></i>
            {{ $t(`登出`) }}
          </span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <ChangePassword ref="changePassword" @success="goToIdaasOut" />
  </div>
</template>

<script>
import { Dropdown, Menu, Avatar } from 'ant-design-vue'
import { logout } from '@common'
import { mapMutations } from 'vuex'
import { redirectUri } from '@src/config/config'
import ChangePassword from '@/component/changePassword'
export default {
  components: {
    'a-avatar': Avatar,
    'a-dropdown': Dropdown,
    'a-menu-item': Menu.Item,
    'a-menu': Menu,
    ChangePassword,
  },
  props: ['list'],
  data() {
    return {
      currentShop: '',
      userName: '',
      shopList: [],
    }
  },
  mounted() {
    this.userName =
      localStorage.getItem('userSession') &&
      JSON.parse(localStorage.getItem('userSession')).realName
  },
  watch: {
    list() {
      this.fetchShopList()
    },
  },
  methods: {
    ...mapMutations(['setState']),
    fetchShopList() {
      let map = new Map()
      this.list.forEach((item) => {
        let key = item.partyId
        if (map.has(key)) {
          let mapItem = map.get(item.partyId)
          mapItem.children.push(item)
          map.set(key, mapItem)
        } else {
          map.set(key, {
            partyName: item.partyName,
            children: [item],
          })
        }
      })
      let arr = []
      map.forEach((value) => {
        arr.push(value)
      })
      this.shopList = arr
      let currentShop = this.$store.state.currentShop
      let userSession = this.$store.state.userSession
      if (currentShop?.partyId == userSession.currentParty.partyId) {
        this.currentShop = currentShop
      } else {
        this.currentShop = arr ? arr[0].children[0] : {}
        this.setState({
          currentShop: this.currentShop,
        })
      }
    },
    dropClick(item) {
      if (item.partyId) {
        this.currentShop = item
        try {
          if (this.currentShop.children) {
            delete this.currentShop.children
          }
          this.setState({
            currentShop: this.currentShop,
          })
        } catch (err) {
          console.log(err)
        }
        this.$emit('changeParty', item)
        return
      } else {
        const key = item.key
        console.log(key)
        switch (key) {
          case 'account':
            this.$router.push('/account/index')
            break
          case 'addShop':
            this.addShopHandle()
            break
          case 'logout':
            this.goToIdaasOut()
            break
          case 'change':
            this.$refs.changePassword.visiable = true
            break
          default:
            break
        }
      }
    },
    addShopHandle() {
      localStorage.removeItem('partyList')
      let url = `https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=${redirectUri}&client_id=120699`
      window.open(url)
    },
    goToIdaasOut() {
      if (window.__POWERED_BY_QIANKUN__) {
        this.$erpLogout()
      } else {
        logout()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';

.menu-icon {
  text-align: center;
  padding: 0 10px;

  .menu-icon-item {
    color: var(--PC);
    font-size: 20px;
  }

  .user_name {
    font-weight: bold;
    font-size: 12px;
    padding-left: 10px;
  }
}

.menu-icon:hover {
  background: rgba(0, 0, 0, 0.025);
}

.active {
  color: $error-color;
}

.boxTitle {
  font-weight: 900;
  padding: 2px 4px;
}

.boxText {
  cursor: pointer;
  padding: 4px 4px;
  border-radius: 3px;
  &.active {
    color: $primary-color;
    font-weight: bold;
  }
}

.boxText:hover {
  background-color: #d1e8fd;
}

.box {
  background-color: white;
  margin: -10px;
  border-radius: 4px;
  width: 380px;
  overflow: hidden;
  padding: 12px 8px;
  max-height: 500px;
  overflow-y: auto;
}
</style>
