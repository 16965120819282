<template>
  <div class="topbar">
    <div class="flex-ct">
      <a-icon
        class="trigger"
        :type="collapsed ? 'menu-unfold' : 'menu-fold'"
        @click="changeCollapsed"
      />
    </div>
    <a-dropdown style="margin-right: auto" v-if="isSub">
      <div class="app-name fz16">
        {{ currentApp.name }}
        <a-icon type="down" />
      </div>
      <a-menu slot="overlay" @click="switchAppHandle">
        <a-menu-item v-for="item in subMerchantAppList" :key="item.appCode">
          <span rel="item-name" :class="{ 'text-info': item.appCode === currentApp.appCode }">
            {{ item.name }}
          </span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <ul class="menu">
      <li class="test-speed mr16">
        <NetworkSpeed />
      </li>
      <li class="timeZone">
        <time-zone></time-zone>
      </li>
      <li class="timeZone" v-if="showMerchant">
        <merchant-list></merchant-list>
      </li>
      <li class="user">
        <user-menu :list="shopList" @changeParty="setParty" />
      </li>
      <li class="user-manual" @click="goToManualPage">
        {{ $t('帮助中心') }}
      </li>
      <li class="lang">
        <lang-menu />
      </li>
    </ul>
    <a-modal
      :maskClosable="false"
      :keyboard="false"
      :visible="msgVisible"
      :title="$t('产品动态')"
      @cancel="msgVisible = false"
      :footer="null"
    >
      <div class="message-list">
        <div class="message-item" v-for="(item, index) in messageList" :key="index">
          <div class="item-title">{{ $t('版本') }}： {{ item.msgTag }}</div>
          <div class="item-time">{{ item.createAt }}</div>
          <div class="item-content" v-for="(elem, index2) in item.items" :key="index2">
            <div class="content-title">
              <a-tag :color="typeMapping[elem.itemType].color">
                {{ typeMapping[elem.itemType].label }}
              </a-tag>
              {{ elem.title }}
            </div>
            <div class="content-sub-title">
              {{ elem.subTitle }}
              <a class="ml6" :href="elem.externalLink" target="_blank" v-if="elem.externalLink">
                {{ $t('查看更多') }}>>
              </a>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Notification } from 'ant-design-vue'
import api from '@/service/api'
import http from '@service/axios'
import title from '@common/title'
import langMenu from './children/lang'
import userMenu from './children/user'
import timeZone from './children/timeZone'
import merchantList from './children/merchantList'
import NetworkSpeed from './children/networkSpeed'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { message } from 'ant-design-vue'
import { callFunctionPeriodically } from '@/common'
export default {
  name: 'Topbar',
  props: ['collapsed'],
  components: {
    'lang-menu': langMenu,
    'user-menu': userMenu,
    'time-zone': timeZone,
    'merchant-list': merchantList,
    NetworkSpeed,
  },
  data() {
    return {
      party: null,
      partyBars: [],
      currentParty: null,
      currentPartyId: null,
      currentSession: {},
      is_change: false,
      shopList: [],
      userInfo: '',
      showMerchant: false,
      messageList: [],
      msgVisible: false,
    }
  },
  computed: {
    ...mapState(['userSession', 'currentApp']),
    ...mapGetters(['subMerchantAppList']),
    typeMapping() {
      return {
        feature: {
          label: this.$t('新功能'),
          color: 'green',
        },
        bug_fix: {
          label: this.$t('问题修复'),
          color: 'volcano',
        },
        improvement: {
          label: this.$t('优化'),
          color: 'orange',
        },
        other: {
          label: this.$t('其他'),
          color: 'blue',
        },
      }
    },
    isSub() {
      return window.__POWERED_BY_QIANKUN__
    },
  },
  watch: {
    userSession(newVal) {
      this.currentSession = newVal
    },
    currentParty(party) {
      if (party) {
        this.chooseParty(party)
      }
    },
  },
  mounted() {
    if (localStorage.getItem('originalMerchantCode') == 'GEEZ') {
      this.showMerchant = true
    } else {
      this.showMerchant = this.$store.state.userInfo.originalMerchantCode == 'GEEZ'
    }
    this.currentParty = this.$store.getters.currentShop //就该叫currentShop 太乱了 切店铺逻辑搞得这么麻烦
    this.setState({
      currentApp: JSON.parse(localStorage.getItem('currentApp') || '{}'),
    })
    this.fetchShopPlatformList()
    if (this.$store.getters.isStore) {
      this.querySystemMessage()

      if (!this.$route.query.isBigCakeOrder) {
        this.queryOrderCountFn()
        window.orderReminderTimer = callFunctionPeriodically(this.queryOrderCountFn, 5 * 60 * 1000)
      }
    }
  },
  methods: {
    ...mapActions(['getShopInfoList']),
    ...mapMutations(['setState']),
    changeCollapsed() {
      this.$emit('changeCollapsed')
    },
    //获取店铺的平台
    fetchShopPlatformList() {
      this.getShopInfoList((list) => {
        this.shopList = list
        let shopPlatformList = list
        http({
          url: api.getCategoryMap,
          type: 'post',
          hasLoading: false,
          success: (res2) => {
            let obj = {},
              map = new Map()
            res2.result?.selectMap?.PLATFORM?.forEach((item) => {
              obj[item.name] = item.desc
            })
            shopPlatformList.forEach((item) => {
              if (!map.has(item.platform)) {
                let platform = `${item.platform}/${item.country?.toLocaleUpperCase()}`
                map.set(platform, {
                  code: item.platform,
                  text: obj[item.platform] || item.platform,
                  country: item.country,
                })
              }
            })
            let nowPlatformList = []
            for (let item of map.values()) {
              nowPlatformList.push(item)
            }
            this.setState({
              shopPlatformList,
              nowPlatformList,
            })
          },
        })
      })
    },
    setParty(party) {
      if (!party || this.currentParty.uniqueCode === party.uniqueCode) return
      if (this.currentParty && party.partyId == this.currentParty.partyId) {
        message.success(this.$t('切换成功'))
        setTimeout(() => {
          history.go(0)
        }, 500)
        return //相同的业务组
      }
      this.currentParty = party
      http({
        url: api.switchParty,
        type: 'post',
        data: { buId: party.partyId },
        success: (res) => {
          const userSession = res.result.userSession
          let party = userSession.currentBu
          party.partyId = party.id
          party.partyName = party.name
          userSession.merchant = {
            merchantCode: userSession.merchantCode,
          }
          userSession.user = {
            name: userSession.userName,
            id: userSession.userCode,
          }
          userSession.currentParty = party //接口换了 只好前端来兼容
          this.setState({
            userSession,
          })
          message.success(this.$t('切换成功'))
          setTimeout(() => {
            history.go(0)
          }, 500)
        },
      })
    },
    chooseParty(party) {
      this.party = party
      if (!this.party) return
      const partyId = this.currentPartyId
      this.currentParty = party
      if (partyId != party.partyId) {
        this.$emit('unready', {})
      }
      setTimeout(() => {
        this.$emit('ready', {})
      }, 0)
      this.ready = true
      this.currentSession.currentParty = party
      this.global.currentSession = this.currentSession
      title({
        session: this.global.currentSession,
        module: this.global.currentModule,
      })
    },
    async querySystemMessage() {
      let res = await http.post(api.querySystemMessage, {
        msgType: 'release_note',
        countryCode: 'CN',
      })
      this.messageList = res.result.list
      if (res.result.list.length > 0) {
        this.msgVisible = true
      }
    },
    goToManualPage() {
      let url = location.origin + '/doc/'
      window.open(url, '_blank')
    },

    queryOrderCountFn() {
      Notification.destroy()
      http({
        url: api.queryOrderCount,
        data: {
          data: {
            statusType: 'waitCheck',
            orderTags: [
              {
                type: 'orderSubType',
                key: 'BIG_CAKE_ORDER',
              },
            ],
          },
        },
        success: (res) => {
          if (+res.result > 0) {
            const text = '您有蛋糕订单未审核'
            const key = `open${Date.now()}`
            Notification.open({
              message: text,
              description: `您有${+res.result}个蛋糕订单未审核`,
              duration: 0,
              icon: <a-icon type="info-circle" style="color: #108ee9" />,
              btn: (h) => {
                return h(
                  'a-button',
                  {
                    props: {
                      type: 'primary',
                      size: 'small',
                    },
                    on: {
                      click: () => {
                        this.closeNotification(key)
                      },
                    },
                  },
                  '去审核'
                )
              },
              key,
            })
            // 检查浏览器是否支持语音合成
            if ('speechSynthesis' in window) {
              const synth = window.speechSynthesis
              const utterance = new SpeechSynthesisUtterance(text)
              synth.speak(utterance)
            } else {
              this.$message.warning(text)
            }
          }
        },
      })
    },
    closeNotification(key) {
      Notification.close(key)
      window.open('/orderManage/saleOrders?isBigCakeOrder=1')
    },
    switchAppHandle({ key }) {
      if (this.currentApp.appCode === key) return
      this.setState({
        panes: [],
      })
      this.$erpJump(key)
    },
  },
}
</script>

<style lang="scss" scoped>
.topbar {
  width: 100%;
  height: 60px;
  background: $primary-color;
  display: flex;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  justify-content: space-between;
  color: $white;

  .trigger {
    font-size: 18px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: $primary-color;
    }
  }

  .menu {
    height: 60px;
    display: flex;
    text-align: center;
    font-size: 14px;
    align-items: center;
    cursor: pointer;

    .user {
      min-width: 100px;
    }

    .lang {
      width: 50px;
    }
  }
}
.message-list {
  .item-title {
    font-size: 18px;
    font-weight: bold;
  }
  .item-time,
  .content-sub-title {
    color: gray;
  }
  .message-item {
    border-bottom: 1px solid #ddd;
    padding-bottom: 6px;
    padding-top: 6px;
    .item-content {
      line-height: 30px;
      margin-bottom: 10px;
    }
    .content-title {
      font-weight: bold;
    }
  }
  .content-sub-title {
    line-height: 22px;
  }
}
.app-name {
  cursor: pointer;
}
</style>
