<template>
  <div class="network-speed-detection">
    <div class="test-sppee" @click="showModal">
      {{ $t('网速检测') }}
      <a-icon type="wifi" />
    </div>
    <a-modal v-model="visible" :title="$t('网速检测')" :footer="null">
      <div class="flex-c-c">
        <div class="modal-text fz16 mb20" style="font-weight: bold">{{ modalText }}</div>
        <a-button type="primary" @click="testIntentSpeed" :loading="loading">
          {{ $t('开始测速') }}
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      loading: false,
      modalText: undefined,
    }
  },
  methods: {
    showModal() {
      this.visible = true
      this.modalText = this.$t('点击按钮开始测速')
    },
    testIntentSpeed() {
      const startTime = new Date().getTime()
      this.loading = true
      this.modalText = this.$t('测速中……')
      fetch(
        'https://cloudoms.oss-ap-southeast-1.aliyuncs.com/1646775072781000706/1051ef384a9540028b7e3fb24a4c7eed?random=' +
          Math.random().toFixed(5)
      )
        .then((response) => {
          if (response.ok) {
            // 如果响应状态码为 200 OK
            return response.blob() // 或 response.arrayBuffer()、response.text()，根据需要选择
          } else {
            throw new Error('下载失败')
          }
        })
        .then((data) => {
          const endTime = new Date().getTime()
          const duration = endTime - startTime
          const downloadSpeed = ~~(data.size / 1024 / (duration / 1000)) // 计算下载速度
          console.log('Download Speed:', downloadSpeed, 'KB/s')
          let text = this.$t(`您的网速为XKB/s`, { X: downloadSpeed })
          if (downloadSpeed < 500) {
            text += ' ' + this.$t('网速不给力啊!')
          } else if (downloadSpeed > 3000) {
            text += ' ' + this.$t('网速很给力啊!')
          }
          this.modalText = text
          this.loading = false
        })
        .catch((error) => {
          console.error('下载失败:', error)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.flex-c-c {
  flex-direction: column;
}
</style>
