<template>
  <a-modal
    :maskClosable="false"
    v-model="visiable"
    :title="$t('修改密码')"
    :confirmLoading="confirmLoading"
    @ok="onOk"
  >
    <div class="tips fz16 mb10" v-if="tips">{{ tips }}</div>
    <a-form-model ref="form" :model="formData" :rules="rules">
      <a-form-model-item :label="$t('商户名称')" required v-if="!hasLogin">
        <a-input v-model="formData.merchantName" />
      </a-form-model-item>
      <a-form-model-item :label="$t('用户名')" required v-if="!hasLogin">
        <a-input v-model="formData.userName" />
      </a-form-model-item>
      <a-form-model-item has-feedback :label="$t('原密码')" required prop="originalPassword">
        <a-input-password v-model="formData.originalPassword" />
      </a-form-model-item>
      <a-form-model-item has-feedback :label="$t('新密码')" required prop="newPassword">
        <a-input-password v-model="formData.newPassword" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import sha512 from 'js-sha512'
import http from '@service/axios'
import api from '@service/api'
import deepClone from '../../common/deepClone'
let reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~!@_#.$%^&*])[A-Za-z\d~!@_#.$%^&*]{8,20}$/
export default {
  props: {
    tips: {
      type: String,
    },
    merchantCode: {
      type: String,
    },
  },
  data() {
    let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入原始密码'))
      } else {
        if (this.formData.newPassword !== '') {
          this.$refs.form.validateField('newPassword')
        }
        callback()
      }
    }
    let validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('密码不能为空，请重新输入'))
      } else if (!reg.test(value)) {
        callback(new Error('密码长度应为8-20位，并且必须包含大写和小写字母、数字、特殊符号'))
      } else {
        callback()
      }
    }
    return {
      visiable: false,
      hasLogin: true,
      formData: {
        merchantName: '',
        userName: '',
        originalPassword: '',
        newPassword: '',
      },
      rules: {
        originalPassword: [
          {
            validator: validatePass,
            trigger: 'change',
          },
        ],
        newPassword: [
          {
            validator: validatePass2,
            trigger: 'change',
          },
        ],
      },
      confirmLoading: false,
    }
  },
  methods: {
    onOk() {
      if (this.hasLogin) {
        this.changePassWord()
      } else {
        this.login()
      }
    },
    login() {
      if (!this.formData.merchantName) {
        return this.$message.warning(this.$t('请输入商家名称'))
      }
      if (!this.formData.userName) {
        return this.$message.warning(this.$t('请输入用户名'))
      }
      if (!this.formData.originalPassword) {
        return this.$message.warning(this.$t('请输入原密码'))
      }
      if (!this.formData.newPassword) {
        return this.$message.warning(this.$t('请输入新密码'))
      }
      this.errorMsg = ''
      const data = deepClone(this.formData)
      const shaObj = new this.$jsSHA('SHA-512', 'TEXT', { encoding: 'UTF8' })
      shaObj.update(data.originalPassword)
      data.password = shaObj.getHash('HEX')
      data.validDays = 7
      http({
        url: api.login,
        type: 'post',
        data,
        success: (res) => {
          let merchantCode = res.result.user.merchantCode
          this.changePassWord(merchantCode)
        },
      })
    },
    changePassWord(merchantCode) {
      if (!reg.test(this.formData.newPassword)) {
        return this.$message.warning(this.$t('新密码格式有误'))
      }
      console.log('changePassWord')
      let data = {
        original_password: sha512(this.formData.originalPassword),
        new_password: sha512(this.formData.newPassword),
        merchant_code: merchantCode || this.merchantCode || this.$store.getters.merchantCode,
      }
      this.confirmLoading = true
      http({
        url: api.updatePassword,
        type: 'post',
        data,
        success: () => {
          this.visiable = false
          this.$message.success(this.$t('修改成功'))
          this.confirmLoading = false
          setTimeout(() => {
            this.$emit('success', this.formData.newPassword)
          }, 800)
        },
        fail: (err) => {
          this.$message.warning(err.msg)
          this.confirmLoading = false
        },
      })
    },
  },
}
</script>
