<template>
  <a-drawer
    width="440"
    :visible="drawerVisible"
    class="custom-class"
    style="color: red"
    :title="$t('客服支持工单')"
    placement="right"
    @close="afterVisibleChange"
  >
    <div class="searchInput flex-ct">
      <a-button type="primary" class="mr10" @click="toWorkOrder">
        <a-icon type="plus" />
      </a-button>
      <a-input-search
        v-model="searchValue"
        style=""
        :placeholder="$t('请输入关键词搜索')"
        @search="onSearch"
      />
    </div>

    <a-list
      class="demo-loadmore-list"
      :loading="initLoading"
      item-layout="horizontal"
      :data-source="list"
    >
      <div
        v-if="hasMore"
        slot="loadMore"
        :style="{ textAlign: 'center', marginTop: '12px', height: '32px', lineHeight: '32px' }"
      >
        <a-spin v-if="initLoading" />
        <a-button v-else @click="onLoadMore">loading more</a-button>
      </div>

      <a-list-item slot="renderItem" slot-scope="item" @click="toDetails(item)">
        <a-row class="title">
          <a-col class="remark">{{ item.remark }}</a-col>
        </a-row>
        <div class="flex-ct" style="width: 100%">
          <p class="user">{{ item.lastUser }}: {{ item.lastMessage }}</p>
          <a-tag :color="statsuMapping[item.status]?.color">
            {{ statsuMapping[item.status]?.name }}
          </a-tag>
        </div>
        <div class="bottomRow flex-ct">
          <div>{{ item.created_at }}</div>
          <div>
            <span v-if="item.created_user">{{ $t(`创建人`) }}：</span>
            {{ item.created_user }}
          </div>
          <div>
            <span v-if="item.source">{{ $t(`来源`) }}：</span>
            {{ item.source }}
          </div>
        </div>
        <div></div>
      </a-list-item>
    </a-list>
  </a-drawer>
</template>
<script>
import api from '@service/api'
import http from '@service/axios'

export default {
  data() {
    return {
      drawerVisible: false,
      page_no: 1,
      total: 0,
      handler: '',
      hasMore: false,
      statsuMapping: {
        FINISHED: {
          color: '#87d068',
          name: this.$t('已完成'),
        },
        PENDING: {
          color: '#faad14',
          name: this.$t('待处理'),
        },
        PROCESSING: {
          color: '#2db7f5',
          name: this.$t('处理中'),
        },
      },
      list: [],
      initLoading: false,
      searchValue: undefined,
    }
  },
  methods: {
    open() {
      this.drawerVisible = true
      this.page_no = 1
      this.list = []
      this.handler = localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo')).userName
        : ''
      this.getList()
    },
    afterVisibleChange() {
      this.drawerVisible = false
    },
    onLoadMore() {
      if (this.page_no * 20 > this.total && this.total > 0) {
        return
      } else {
        this.page_no += 1
        this.getList()
      }
    },
    onSearch() {
      this.page_no = 1
      this.list = []
      this.getList()
    },
    getList() {
      this.initLoading = true
      http({
        url: api.workOrderList,
        data: {
          page_no: this.page_no,
          page_size: 20,
          categoryList: [1, 2],
          handler: this.handler,
          remark: this.searchValue,
          orderByParam: 'created_at desc',
        },
        success: (res) => {
          res.result.list.forEach((item) => {
            if (item.workorder_record_list.length > 0) {
              item.workorder_record_list.forEach((t) => {
                if (t.status == 'FINISHED') {
                  item.lastUser = t.handler
                  item.lastMessage = t.work_desc
                }
              })
            }
            this.list.push(item)
          })
          this.total = res.result.total
          this.initLoading = false
          this.hasMore = this.list.length === res.result.page_size
        },
      })
    },
    toDetails(value) {
      this.$router.push({
        path: '/workorder_web/workorder/detail',
        query: { order_id: value.order_id },
      })
      this.afterVisibleChange()
    },
    toWorkOrder() {
      this.$router.push('/workorder_web/workorder/create?type=CustomerService')
      this.afterVisibleChange()
    },
  },
}
</script>
<style lang="scss" scoped>
.searchInput {
  position: absolute;
  width: 398px;
  background-color: white;

  z-index: 5;
  top: 55px;
  padding-top: 17px;
  padding-bottom: 17px;
}
.demo-loadmore-list {
  min-height: 350px;
  margin-top: 30px;
}
.ant-list-item {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-family: '微软雅黑';
  font-weight: 900;
}
.bottomRow {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 12px;
  color: #00000073;
  font-weight: 500;
}
.remark {
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;

  height: 20px;
}
.title {
  width: 100%;
}
.user {
  text-align: left;
  width: 100%;
  font-weight: bold;
  font-size: 15px;
  color: rgb(97, 97, 97);
  margin: 2px 0;
}
.ant-list-item {
  cursor: pointer;
  &:hover {
    background: #eee;
  }
}
</style>
