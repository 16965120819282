<template>
  <a-dropdown style="margin-right: 10px" placement="bottomLeft">
    <div class="menu-icon flex-ct">
      {{ $t(`时区`) }}：
      <span class="mr4">{{ time_zone_name }}</span>
      <i class="fa fa-angle-down"></i>
    </div>
    <a-menu slot="overlay" @click="changeLang">
      <template v-for="item in time_zone_list">
        <a-menu-item :key="item.value">
          <span rel="noopener noreferrer" :class="{ active: item.value === time_zone }">
            {{ item.name }}
          </span>
        </a-menu-item>
      </template>
    </a-menu>
  </a-dropdown>
</template>

<script>
export default {
  data() {
    return {
      time_zone: 0,
    }
  },
  mounted() {
    this.time_zone = +localStorage.getItem('timezoneDiffBeijing')
  },
  computed: {
    time_zone_name() {
      return this.time_zone_list.find((item) => item.value === this.time_zone).name
    },
    time_zone_list() {
      return [
        {
          value: 0,
          name: this.$t(`中国`),
        },
        {
          value: 1,
          name: this.$t(`泰国`),
        },
      ]
    },
  },
  methods: {
    changeLang(item) {
      this.$success(this.$t('切换成功'))
      this.time_zone = item.key
      localStorage.setItem('timezoneDiffBeijing', item.key)
      history.go(0)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.active {
  color: $primary-color;
}
</style>
