<template>
  <div class="alive-tab">
    <div class="operate-content">
      <a-dropdown placement="bottomLeft">
        <a-icon class="close-circle" type="close-circle" />
        <a-menu slot="overlay">
          <a-menu-item>
            <span class="menu-text" @click="closeTab(1)">{{ $t('关闭所有') }}</span>
          </a-menu-item>
          <a-menu-item>
            <span class="menu-text" @click="closeTab(0)">{{ $t('关闭其他') }}</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
    <a-tabs v-model="activeKey" hide-add type="editable-card" @edit="onEdit" @tabClick="tabClick">
      <a-tab-pane v-for="pane in panes" :key="pane.view">
        <div slot="tab" class="tab-item">
          <div class="icon-active" v-if="activeKey === pane.view" @click="reloadHandle(pane.view)">
            <div class="circle">
              <i class="fa-solid fa-rotate-right icon reload"></i>
            </div>
          </div>
          <div class="circle" v-else></div>
          <span>{{ $t(`menu.${pane.name || pane.view}`, { OrderSn: pane.params?.orderSn }) }}</span>
          <a-icon
            class="icon close"
            type="close"
            @click.stop.prevent="remove(pane.view)"
            v-if="panes.length > 1"
          ></a-icon>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { Dropdown, Menu } from 'ant-design-vue'
import { mapState, mapMutations } from 'vuex'
import apps from '@/qiankun/apps.js'
export default {
  data() {
    return { activeKey: '' }
  },
  components: {
    'a-menu': Menu,
    'a-dropdown': Dropdown,
    'a-menu-item': Menu.Item,
  },
  computed: {
    ...mapState(['exclude', 'panes']),
    mircoRoutes() {
      return apps.map((item) => item.name)
    },
  },
  mounted() {
    // console.log('this.$route: ', this.$route)
    if (this.panes.length === 0) {
      let path = this.$route.path.slice(1),
        name = this.$route.params.pathMatch
      this.panes.push({
        name,
        view: path,
      })
    }
    this.getRouteActive(this.$route)
  },
  methods: {
    ...mapMutations(['removePane', 'setState']),
    getName(key) {
      let nameList = key.split('/')
      return nameList[0]
    },
    getOrderSn(key) {
      let nameList = key.split('/')
      return nameList[1]
    },
    tabClick(key) {
      if (this.activeKey === key) return
      let name = this.getName(key)
      console.log('name: ', name)
      let item = this.panes.find((item) => item.view === key)
      const data = { name, query: item.query, params: item.params }
      if (name === 'orderDetails') {
        //订单详情
        if (item.params?.orderId) {
          //原始订单跳转有orderId
          this.$router.push('/orderManage/omsOrderDetail/orderDetails/' + item.params.orderId)
        } else {
          //原始订单跳转没有orderId
          this.$router.push(
            '/orderManage/omsOrderDetail/orderDetails/?orderSn=' + item.params.orderSn
          )
        }
      } else if (name === 'purchaseDetail') {
        //采购详情
        this.$router.push('/purchase/purchaseDetail/?id=' + item.params.orderId)
      } else if (this.mircoRoutes.includes(name)) {
        //子应用
        this.$router.push('/' + key)
      } else if (name !== this.$route.name) {
        this.$router.push(data)
      }
    },
    onEdit(targetKey, action) {
      this[action](targetKey)
    },
    remove(targetKey) {
      let activeKey = this.activeKey
      this.removePane({
        activeKey,
        targetKey,
        callback: (activeKey) => {
          if (this.activeKey !== activeKey) {
            this.tabClick(activeKey)
          }
        },
      })
    },
    reloadHandle(key) {
      // console.log('key: ', key)
      //子应用刷新有点问题 TODO
      let name = this.getName(key)
      this.setState({
        exclude: name,
      })
    },
    closeTab(is_all) {
      let path = this.$route.path.slice(1),
        name = this.$route.params.pathMatch
      let panes = [{ view: path, name }]
      if (!is_all) {
        panes = this.panes.filter((item) => item.view === this.activeKey)
        if (panes.length === 0) {
          panes = [{ view: path, name }]
        }
      }
      this.setState({
        panes,
      })
    },
    getRouteActive(route) {
      let name = this.getName(route.name)
      if (route.fullPath.includes('orderManage/omsOrderDetail/orderDetails')) {
        if (route.query?.orderSn) {
          this.activeKey = 'orderDetails/' + route.query?.orderSn
        } else {
          let routeArr = route.fullPath.split('/')
          let orderId = routeArr[routeArr.length - 1]
          let item = this.panes.find((item) => String(item?.params?.orderId) === String(orderId))
          let orderSn = item?.params?.orderSn
          this.activeKey = 'orderDetails/' + orderSn
        }
        return
      } else if (route.fullPath.includes('purchase/purchaseDetail')) {
        this.activeKey = 'purchaseDetail/' + route.query?.id
        return
      }
      this.activeKey = name
      if (this.mircoRoutes.includes(name)) {
        this.activeKey += '/' + route.params.pathMatch
        if (route.path.includes('aftersale_web/aftersale/detail')) {
          let arr = route.params.pathMatch.split('/').reverse()
          this.getActiveKey(arr[0])
        }
      }
    },
    getActiveKey(orderId) {
      let item = this.panes.find((elem) => +elem.params?.orderId === +orderId)
      this.activeKey = item?.view
    },
  },
  watch: {
    $route(newVal) {
      this.getRouteActive(newVal)
    },
  },
}
</script>

<style lang="scss">
.alive-tab {
  background: $bg-gray-color;
  overflow: hidden;
  padding-left: 10px;
  width: 100%;
  display: flex;
  .operate-content {
    height: 40px;
    line-height: 40px;
    padding-right: 6px;
    cursor: pointer;
    .menu-text {
      font-size: 13px;
    }
  }
  .spin {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 99999;
  }
  .ant-tabs-card > .ant-tabs-bar {
    border-bottom: none;
  }
  .tab-item {
    width: 100%;
    padding: 0 8px 0 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .circle {
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: $bg-gray-color;
      margin-left: 4px;
      margin-right: 10px;
    }
    .icon {
      font-size: 10px;
      font-weight: bold;
      padding: 4px;
      margin-right: 0;
    }
    .close {
      margin-left: 4px;
      color: $font-color-disabled;
      &:hover {
        color: $font-color-base;
      }
    }
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    border-color: $white;
    background: $white;
    height: 32px;
    line-height: 32px;
    margin-right: 6px;
    margin-top: 4px;
    border-radius: 0;
    padding: 0;
    font-size: 13px;
    &:hover {
      color: $font-color-base;
    }
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    border-color: $white;
    background: $white;
    border-radius: 0;
    .circle {
      background: $primary-color;
      .icon {
        display: none;
      }
    }
    &:hover {
      color: $primary-color;
      .circle {
        height: auto;
        margin-left: 0;
        margin-right: 14px;
        background: transparent;
      }
      .icon {
        display: block;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  }
  .ant-tabs-nav .ant-tabs-tab .anticon {
    margin-right: 0;
  }
}

.ant-breadcrumb-separator {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.65);
}
</style>
